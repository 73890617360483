import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import HeaderSection from "../src/components/HeaderSection";
import FooterSection from "../src/components/FooterSection";
import Single from './pages/Single';

function App() {
  return (
    <Router>
      <div className="app-container">
        <div className="nv">
           <HeaderSection />
        </div>
        <div className="main">
            <Routes>
                <>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/about" element={<About />} />
                <Route exact path="/single" element={<Single />} />
                </>
            </Routes>
        </div>
       <div className="ft">
       <FooterSection />
       </div>
      </div>
    </Router>

  );
}

export default App;
