import React, { useEffect, useState } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import { Link } from 'react-router-dom';

function Home() {

  const [catalogue, setCatalogue]        = useState([]);
  const [catPictures, setCatPictures]    = useState([]);
 
  useEffect(() =>{
    getMyCatalogue();

  },[]);
  

  useEffect(() => {  
      if(catalogue){
        console.log("We can search pictures");
        getAllCatPictures();
      }else{
        console.log("Got started")
      }
     
  },[catalogue]);

  const getMyCatalogue = async () => {
    try{
      console.log("TOKEN " + CONSTANTS.API_TOKEN);
      //console.log("################");
      const res = await axios.get(CONSTANTS.API_URL +"catalogues/list", {
            headers: {
              api_key: CONSTANTS.API_TOKEN
            }
        });
      
        console.log(res.data);
        setCatalogue(res.data);
        console.log("################");
    }catch(ex){
      console.log("GET Catalogue Error");
      console.log(ex);
    }
  }

  const getAllCatPictures = async () => {
    try{
      //console.log("*********************************");
      console.log("The catalogue ID " + catalogue._id);
      const catalogueID = catalogue._id;
      const res = await axios.get(CONSTANTS.API_URL +"catalogues/picturelist/", {
            headers: {
              api_key: CONSTANTS.API_TOKEN
            }
        });
      /*
        console.log(res.data);
        console.log(res.data);
        */
        setCatPictures(res.data);
        console.log("*********************************");
    }catch(ex){
      console.log("GET Pictures Error");
      console.log(ex);
    }
  }

  return (
    <div className="main-area container">
      
        <h1>Catalogue</h1>
        <div className="list-photos">
            {
              catPictures && (
                <div className="row listing">
                   {catPictures.map((pic) => {
                    return <div className="col-md-3" key={pic._id}>
                      <div className="pic-box">
                          <h4 className="tt-bx">{pic.title}</h4>
                          <div className="image-prod"  style={{ 
                                backgroundImage: `url(${pic.imgurl})` 
                              }} ></div>
                              <Link 
                                  to={`/single/`}
                                  className="btn btn-purple" 
                                  state={{ picturesitem: pic}}>
                                View
                              </Link>
                      </div>
                    </div>
                   })}
                </div>
              )
            }
        </div>
    </div>
  )
}

export default Home