import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function Single() {
  const location = useLocation();
  useEffect(() => {
    console.log("user");
    console.log(location.state.picturesitem);
  }, []);
  return (
    <div className="main-area container">
        <div className="row">
          <div className="col-md-6">
             <div className="side-info left-info">
              <h2>{location.state.picturesitem.title}</h2>
              <div className="image-area">
                  <div className="image-spots"></div>
                  <div className="mainimage-flap"
                     style={{ 
                      backgroundImage: `url(${location.state.picturesitem.imgurl})` 
                    }}>

                  </div>
              </div>
             </div>
          </div>
          <div className="col-md-6">
             <div className="side-info right-info">
                 <div className="pro-info">
                    <div className="lbl-titles">
                      Description:
                    </div>
                    <div className="lbl-content">
                      {location.state.picturesitem.description}
                    </div>
                 </div>
                 <div className="pro-info">
                    <div className="lbl-titles">
                      Size:
                    </div>
                    <div className="lbl-content">
                      {location.state.picturesitem.size}
                    </div>
                 </div>
             </div>
          </div>
        </div>
    </div>
  )
}

export default Single